import {Guid} from "guid-typescript";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {CarrierType} from "@/api/v1/CarrierApi";

export const ParcelApi = {
    create(clientId: Guid, orderId: Guid, parcel: Parcel, packagingId: Guid | null): Promise<void> {
        return postRequest(getBaseUrl(clientId, orderId), {
            items: parcel.items,
            packagingId: packagingId,
            length: parcel.length,
            height: parcel.height,
            width: parcel.width,
            dimensionsUnit: parcel.dimensionsUnit,
            weight: parcel.weight,
            weightUnit: parcel.weightUnit,
            isTube: parcel.isTube,
        });
    },
    markDispatched(clientId: Guid, orderId: Guid, parcelId: Guid, force = false): Promise<void> {
        return postRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}/mark-dispatched`, { force });
    },
    find(clientId: Guid, orderId: Guid, queryRequest: IQueryRequest): Promise<IPaginatedList<Parcel>> {
        return getRequest(getBaseUrl(clientId, orderId), queryRequest);
    },
    findById(clientId: Guid, orderId: Guid, parcelId: Guid): Promise<Parcel> {
        return getRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}`);
    },
    findLabels(clientId: Guid, orderId: Guid, parcelId: Guid): Promise<Label[]> {
        return getRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}/labels`);
    },
    update(clientId: Guid, orderId: Guid, parcelId: Guid, parcel: Parcel): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}`, parcel);
    },
    useOrderSheet(clientId: Guid, orderId: Guid, parcelId: Guid, orderSheetId: Guid): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}/order-sheet`, {orderSheetId});
    },
    useCollectionAddress(clientId: Guid, orderId: Guid, parcelId: Guid, collectionAddressId: Guid): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}/collection-address`, collectionAddressId);
    },
    delete(clientId: Guid, orderId: Guid, parcelId: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId, orderId)}/${parcelId}`);
    }
};

function getBaseUrl(clientId: Guid, orderId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/orders/${orderId}/parcels`;
}

export interface Size {
    length?: number;
    width?: number;
    height?: number;
    dimensionsUnit: string;
    weight?: number;
    weightUnit: string;
    isTube: boolean;
}

export interface Parcel extends Size {
    clientId: Guid;
    orderId: Guid;
    id: Guid;
    packagingId: Guid;
    items: any[];
    status: number;
    totalWeightInKg: number;
    orderSheetId?: Guid;
    carrier?: string;
    carrierType?: CarrierType;
    carrierService?: string;
    carrierFormat?: string;
    carrierReference?: string;
    trackingNumber?: string;
    hasLabels?: string;
    carrierAccountId?: Guid;
    createdAt: string;
    updatedAt: string;
    dispatchedAt?: string;
}

export function emptyParcel(): Parcel {
    return {
        clientId: Guid.createEmpty(),
        orderId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        packagingId: Guid.createEmpty(),
        items: [],
        status: 0,
        totalWeightInKg: 0,
        dimensionsUnit: "cm",
        weightUnit: "kg",
        isTube: false,
        createdAt: "",
        updatedAt: "",
    };
}

export interface Label {
    id: Guid;
    parcelId: Guid;
    size: string;
    format: string;
    content: string;
    type: LabelType;
    reference?: string;
    url?: string;
}

export enum LabelType {
    Primary = 0,
    Collection = 1,
    Document = 2
}
