var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',_vm._b({},'div',_vm.$attrs,false),[_c('data-table',{attrs:{"title":_vm.$t('select-service'),"columns":_vm.columns,"data-provider":_vm.dataProvider,"show-plus":false,"show-search":true,"fixed-headers":true,"initial-order-by":"totalPrice","search-placeholder":"Search by service or carrier name"},on:{"query-change":_vm.queryChange},scopedSlots:_vm._u([{key:"image",fn:function(ref){
var item = ref.item;
return [_c('img',{staticClass:"mx-auto d-block",attrs:{"alt":item.carrierName,"src":item.imageLink}})]}},{key:"serviceName",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.decode(item.serviceName))+" ")]}},{key:"serviceLink",fn:function(ref){
var item = ref.item;
return [_c('a',{attrs:{"href":item.serviceLink,"target":"_blank"}},[_vm._v("Information")])]}},{key:"totalPrice",fn:function(ref){
var item = ref.item;
return [_vm._v(" £"+_vm._s(item.totalPrice.toFixed(2))+" ")]}},{key:"transitTime",fn:function(ref){
var item = ref.item;
return [_c('p',{staticStyle:{"white-space":"pre-wrap"}},[_vm._v(_vm._s(item.transitTime.trim()))])]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('button',{staticClass:"btn btn-primary float-right",attrs:{"disabled":_vm.selecting},on:{"click":function($event){return _vm.showConfirmModal(item)}}},[_vm._v(" "+_vm._s(_vm.$t("purchase-service"))+" ")])]}}])}),(_vm.showErrorsModal)?_c('modal',{attrs:{"title":"Error Fetching Quotes"},on:{"done":function($event){_vm.showErrorsModal = false}}},[_c('request-error-alert',{staticClass:"mt-3",attrs:{"title":_vm.$t('failed-select-service'),"value":_vm.errors}})],1):_vm._e(),(_vm.quoteToSelect)?_c('modal',{attrs:{"title":_vm.$t('purchase-service')},on:{"done":function($event){_vm.quoteToSelect = null}},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.closeSelectModal}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('button',{staticClass:"btn btn-primary",attrs:{"type":"button","disabled":_vm.selecting},on:{"click":_vm.selectService}},[_vm._v(" "+_vm._s(_vm.$t("purchase-service"))+" ")])]},proxy:true}],null,false,3951246713)},[_c('p',[_vm._v("Are you sure you want to select "+_vm._s(_vm.quoteToSelect.serviceName)+"?")]),(_vm.selecting)?_c('div',{staticClass:"text-center"},[_c('span',{staticClass:"spinner-border text-primary mt-3"})]):_vm._e(),(_vm.errors)?_c('request-error-alert',{staticClass:"mt-3",attrs:{"title":_vm.$t('failed-select-service'),"value":_vm.errors}}):_vm._e()],1):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }