


















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import DataTable from "@/components/DataTable.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import {Guid} from "guid-typescript";
import {Product} from "@/api/v1/ProductApi";
import {NewOrderItem} from "@/api/v1/OrderApi";
import {ProductVariation} from "@/api/v1/ProductVariationApi";
import {LegacyProduct} from "@/api/v1/LegacyProductApi";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {
        SelectionTable,
        DataTable,
        Modal
    }
})
export default class AddOrderItem extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    selectedProduct: Product[] = [];
    selectedLegacyProduct: LegacyProduct[] = [];
    selectedProductVariation: ProductVariation[] = [];

    showProductVariationTable = false;

    item: NewOrderItem = {
        product: null,
        legacyProduct: null,
        variation: null,
        quantity: 1,
        price: 0
    };

    price = 0;

    @Watch("price")
    onPriceChange() {
        this.item.price = this.price * 100;
    }

    get selectedLegacyProductId(): Guid {
        return this.item.legacyProduct?.id ?? Guid.createEmpty();
    }

    @Watch("selectedProduct")
    onSelectedProductUpdate() {
        if (this.selectedProduct.length > 0) {
            this.item.product = this.selectedProduct[0];
        }
    }

    @Watch("selectedLegacyProduct")
    onSelectedLegacyProductUpdate() {
        if (this.selectedLegacyProduct.length > 0) {
            this.item.legacyProduct = this.selectedLegacyProduct[0];
            this.showProductVariationTable = true;
        }
    }

    @Watch("selectedProductVariation")
    onSelectedProductVariationUpdate() {
        if (this.selectedProductVariation.length > 0) {
            this.item.variation = this.selectedProductVariation[0];
        }
    }

    close() {
        this.$emit("close");
    }

    add() {
        //TODO validation
        this.$emit("add", this.item);
    }

    clearProduct() {
        this.selectedProduct = [];
        this.item.product = null;
    }

    clearLegacyProduct() {
        this.selectedLegacyProduct = [];
        this.item.legacyProduct = null;
        this.clearProductVariation();
        this.showProductVariationTable = false;
    }

    clearProductVariation() {
        this.selectedProductVariation = [];
        this.item.variation = null;
    }


}
