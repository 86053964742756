











































































































































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    displayDate,
    displayDateTime,
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    FilterOperand,
    getQueryStringKey,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import {Order, OrderApi, OrderStatus} from "@/api/v1/OrderApi";
import IconButton from "@/components/helpers/IconButton.vue";
import {addDays, formatISO, startOfTomorrow} from "date-fns";
import OrderSourceLogo from "@/components/OrderSourceLogo.vue";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import Icon from "@/components/helpers/Icon.vue";
import MobileOnlyAccordion from "@/components/MobileOnlyAccordion.vue";
import Helper from "@/components/helpers/Helper.vue";
import DateInput from "@/components/DateInput.vue";
import Modal from "@/components/Modal.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import {User} from "@/api/v1/UserApi";
import {OrderUserAssignmentApi} from "@/api/v1/OrderUserAssignmentApi";

@Component({
    components: {SelectionTable, Modal, DateInput, MobileOnlyAccordion, OrderSourceLogo, DataTable, IconButton, Icon}
})
export default class OrderTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    dataProvider: Promise<IPaginatedList<Order>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, "placedAt", true, "", [], "orders");
    sourceFilterValue: OrderSourceType | null = null;
    statusFilterValue: OrderStatus | null = OrderStatus.Outstanding;
    placedAtStartFilterValue = "";
    placedAtEndFilterValue = "";
    assignedToMeFilterValue = false;

    OrderSourceType: typeof OrderSourceType = OrderSourceType;

    outstandingAndProcessingValue = 1000;

    selectedUser: User[] = [];
    selectedOrders: Order[] = [];
    showModal = false;

    get columns(): ITableColumn[] {
        return [
            {label: "Source", key: "source", sortable: false},
            {label: "Source Name", key: "orderSourceName", sortable: true},
            {label: "Source ID", key: "sourceId", sortable: true, headerTitle: "ID of order at source"},
            {label: "Status", key: "status", sortable: true},
            {label: "Buyer Name", key: "buyerName", sortable: true, itemStopClick: true},
            {label: "Placed At", key: "placedAt", sortable: true},
            {label: "Dispatch By", key: "dispatchBy", sortable: true},
            {label: "Items", key: "items", sortable: false},
            {label: "", key: "rowActions", sortable: false},
        ];
    }

    get maxEndDateForStart(): Date | string {
        return this.placedAtEndFilterValue === "" ? new Date() : this.placedAtEndFilterValue;
    }

    get tomorrow(): string {
        return startOfTomorrow().toISOString().split("T")[0];
    }

    displayDateTime(date: string | Date): string {
        return displayDateTime(date);
    }

    displayDate(date: string | Date): string {
        return displayDate(date);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("placedAtStartFilterValue")
    onStartDateFilterUpdate() {
        const startDateFilter = this.query.filters.find(f => f.name === "placedAt" && f.op == FilterOperand.GreaterThanOrEquals);
        if (startDateFilter === undefined) {
            this.query.filters.push({
                name: "placedAt",
                op: FilterOperand.GreaterThanOrEquals,
                value: this.placedAtStartFilterValue
            });
        } else {
            if (this.placedAtStartFilterValue)
                startDateFilter.value = this.placedAtStartFilterValue;
            else
                this.query.filters = this.query.filters.filter(f => !(f.name === "placedAt" && f.op === FilterOperand.GreaterThanOrEquals));
        }

        this.refresh();
    }

    @Watch("placedAtEndFilterValue")
    onEndDateFilterUpdate() {
        const endDateFilter = this.query.filters.find(f => f.name === "placedAt" && f.op == FilterOperand.LessThanOrEquals);
        const dateValue = this.placedAtEndFilterValue ? addDays(new Date(this.placedAtEndFilterValue), 1) : null;
        const dateString = dateValue ? formatISO(dateValue, {representation: "date"}) : null;
        if (endDateFilter === undefined) {
            this.query.filters.push({
                name: "placedAt",
                op: FilterOperand.LessThanOrEquals,
                value: dateString ?? this.tomorrow
            });
        } else {
            endDateFilter.value = dateString ?? this.tomorrow;
        }

        this.refresh();
    }

    @Watch("statusFilterValue")
    onStatusFilterUpdate() {
        const statusFiltersExist = this.query.filters.some(f => f.name === "status");

        if (statusFiltersExist) {
            this.query.filters = this.query.filters.filter(f => f.name !== "status");
        }

        this.refresh();
    }

    @Watch("sourceFilterValue")
    onSourceFilterUpdate() {
        const sourceFilter = this.query.filters.find(f => f.name === "orderSourceType");
        if (sourceFilter) {
            if (this.sourceFilterValue === null) {
                this.query.filters = this.query.filters.filter(f => f.name !== "orderSourceType");
            }
            else sourceFilter.value = this.sourceFilterValue;
        }

        this.refresh();
    }

    @Watch("assignedToMeFilterValue")
    onAssignedToMeFilterValueChanged() {
        const searchParams = new URLSearchParams(window.location.search);
        searchParams.set(getQueryStringKey(this.query, "assignedToMe"), this.assignedToMeFilterValue.toString());
        const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
        history.replaceState(null, "", newRelativePathQuery);
        this.refresh();
    }

    refresh() {
        if (this.query.filters.find(f => f.name === "status") === undefined && this.statusFilterValue !== null) {
            if (this.statusFilterValue === this.outstandingAndProcessingValue) {
                this.query.filters = this.query.filters.filter(f => f.name !== "status");
                this.query.filters.push({
                    name: "status",
                    op: FilterOperand.Equals,
                    value: OrderStatus.Outstanding
                });
                this.query.filters.push({
                    name: "status",
                    op: FilterOperand.Equals,
                    value: OrderStatus.Processing
                });
            } else {
                this.query.filters.push({
                    name: "status",
                    op: FilterOperand.Equals,
                    value: this.statusFilterValue
                });
            }
        }
        if (this.query.filters.find(f => f.name === "orderSourceType") === undefined && this.sourceFilterValue !== null) {
            this.query.filters.push({
                name: "orderSourceType",
                op: FilterOperand.Equals,
                value: this.sourceFilterValue
            });
        }
        const searchParams = this.query.getFiltersAsSearchParams();
        const newRelativePathQuery = window.location.pathname + "?" + searchParams.toString();
        history.replaceState(null, "", newRelativePathQuery);

        this.dataProvider = OrderApi.find(this.clientId, this.assignedToMeFilterValue, this.query);
    }

    create() {
        this.$router.push({name: "createOrder"});
    }

    navigate(item: Order) {
        if (item.id) {
            this.$router.push({
                name: "orderOverview",
                params: {
                    clientId: this.clientId.toString(),
                    orderId: item.id.toString()
                }
            });
        }
    }

    navigateNewTab(item: Order) {
        if (item.id) {
            const routeData = this.$router.resolve({
                name: "orderOverview",
                params: {
                    clientId: this.clientId.toString(),
                    orderId: item.id.toString()
                }
            });

            window.open(routeData.href, "_blank");
        }
    }

    closeModal() {
        this.showModal = false;
        this.selectedUser = [];
        this.selectedOrders = [];
    }

    assignUser() {
        if (this.selectedUser[0] && this.selectedOrders.length > 0) {
            OrderUserAssignmentApi.assignOrdersToUser(this.clientId, this.selectedUser[0].id, this.selectedOrders.map(o => o.id!))
                .then(() => {
                    this.closeModal();
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast("Failed to assign orders to user", ErrorToastSettings);

                    throw err;
                });
        }
    }

    mounted() {
        const searchParams = new URLSearchParams(window.location.search);
        const assignedToMe = searchParams.get(getQueryStringKey(this.query, "assignedToMe"));
        if (assignedToMe) {
            this.assignedToMeFilterValue = assignedToMe === "true";
        } else {
            this.assignedToMeFilterValue = !this.permissionCheck("order:read:historical");
        }

        const statusFilter = this.query.filters.filter(f => f.name === "status");
        if (statusFilter.length === 2) {
            this.statusFilterValue = this.outstandingAndProcessingValue;
        } else {
            this.statusFilterValue = statusFilter[0].value as OrderStatus;
        }

        const sourceFilter = this.query.filters.filter(f => f.name === "orderSourceType");
        if (sourceFilter.length === 1) {
            this.sourceFilterValue = sourceFilter[0].value as OrderSourceType;
        }

        const placedAtFilters = this.query.filters.filter(f => f.name === "placedAt");
        placedAtFilters.forEach(f => {
            if (f.op === FilterOperand.GreaterThanOrEquals) {
                this.placedAtStartFilterValue = f.value as string;
            }
        });
    }
}
