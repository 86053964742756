

















































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList
} from "@/api/Utils";
import DataTable from "@/components/DataTable.vue";
import Icon from "@/components/helpers/Icon.vue";
import {Carrier, CarrierApi, CarrierType, emptyCarrier} from "@/api/v1/CarrierApi";
import Check from "@/components/helpers/Check.vue";
import Modal from "@/components/Modal.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import RequestErrorAlert from "@/components/RequestErrorAlert.vue";
import {ParcelApi} from "@/api/v1/ParcelApi";
import {ShippingQuote, TGEParcelShippingApi} from "@/api/v1/TGEParcelShippingApi";
import htmlEntitiesDecode from "html-entities-decoder";

@Component({components: {RequestErrorAlert, DataTable, Icon, Check, Modal, SelectionTable}})
export default class TransglobalExpressShippingQuotesTable extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly parcelId!: Guid;
    @Prop({type: String, required: true}) readonly carrierId!: Guid;
    @Prop({type: String, required: true}) readonly collectionAddressId!: Guid;

    dataProvider: Promise<IPaginatedList<ShippingQuote>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();

    carrier: Carrier = emptyCarrier();
    CarrierType: typeof CarrierType = CarrierType;

    quoteToSelect: ShippingQuote | null = null;
    selecting = false;

    errors: any = {};
    showErrorsModal = false;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("carrier-name"), key: "carrierName", sortable: false},
            {label: this.$t("name"), key: "serviceName", sortable: false},
            {label: this.$t("link"), key: "serviceLink", sortable: false},
            {label: this.$t("speed"), key: "transitTime", sortable: false},
            {label: this.$t("price"), key: "totalPrice", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    decode(str: string): string {
        return htmlEntitiesDecode(str);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("format")
    refresh() {
        ParcelApi.useCollectionAddress(this.clientId, this.orderId, this.parcelId, this.collectionAddressId)
            .then(() => {
                this.dataProvider = TGEParcelShippingApi.listQuotes(this.clientId, this.parcelId, this.carrierId, this.query)
                    .catch(err => {
                        if (err.status === 400 || err.status === 422) {
                            this.errors = err.data.errors;
                            this.showErrorsModal = true;
                        } else {
                            this.$bvToast.toast("Failed to list quotes", ErrorToastSettings);
                        }

                        throw err;
                    });
            })
            .catch(err => {
                this.$bvToast.toast("Failed to set collection address", ErrorToastSettings);

                throw err;
            });
    }

    closeSelectModal() {
        this.errors = {};
        this.quoteToSelect = null;
    }

    showConfirmModal(item: ShippingQuote) {
        this.errors = {};
        this.quoteToSelect = item;
    }

    selectService() {
        if (this.quoteToSelect) {
            this.selecting = true;
            this.errors = {};
            TGEParcelShippingApi.buyPostage(this.clientId, this.parcelId, this.carrierId,
                this.quoteToSelect)
                .then(() => {
                    this.$router.push({
                        name: "parcelOverview", params: {
                            clientId: this.clientId.toString(),
                            orderId: this.orderId.toString(),
                            parcelId: this.parcelId.toString()
                        }
                    });
                    this.selecting = false;
                })
                .catch(err => {
                    if (err.status === 400 || err.status === 422) {
                        this.errors = err.data.errors;
                        this.selecting = false;
                    } else if (err.status === 408) {
                        this.errors = err.data.errors;
                        setTimeout(() => {
                            this.$router.push({
                                name: "parcelOverview", params: {
                                    clientId: this.clientId.toString(),
                                    orderId: this.orderId.toString(),
                                    parcelId: this.parcelId.toString()
                                }
                            });
                        }, 2500);
                    } else {
                        this.selecting = false;
                        this.$bvToast.toast(this.$t("failed-select-service").toString(), ErrorToastSettings);

                        throw err;
                    }
                });
        }
    }

    created() {
        CarrierApi.findById(this.clientId, this.carrierId)
            .then(data => {
                this.carrier = data;
            });
    }
}
