import {Guid} from "guid-typescript";
import {deleteRequest, getRequest, postFilesRequest} from "@/api/HttpMethods";

export const OrderFilesApi = {
    store(clientId: Guid, orderId: Guid, files: File[]): Promise<void> {
        return postFilesRequest(getBaseUrl(clientId, orderId), files);
    },
    list(clientId: Guid, orderId: Guid): Promise<string[]> {
        return getRequest(getBaseUrl(clientId, orderId));
    },
    downloadAllHref(clientId: Guid, orderId: Guid): string {
        return `${getBaseUrl(clientId, orderId)}/download`;
    },
    downloadHref(clientId: Guid, orderId: Guid, name: string): string {
        return `${getBaseUrl(clientId, orderId)}/download?name=${name}`;
    },
    delete(clientId: Guid, orderId: Guid, name: string): Promise<void> {
        return deleteRequest(getBaseUrl(clientId, orderId), name);
    }
};

function getBaseUrl(clientId: Guid, orderId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/orders/${orderId}/files`;
}
