import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const PackagingApi = {
    create(clientId: Guid, packaging: Packaging): Promise<Packaging> {
        return postRequest(getBaseUrl(clientId), packaging);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<Packaging>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<Packaging> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, product: Packaging): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, product);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/packaging`;
}

export interface Packaging {
    clientId: Guid;
    id: Guid;
    name: string;
    length?: number;
    width?: number;
    height?: number;
    dimensionsUnit: string;
    weight?: number;
    weightUnit: string;
    isTube: boolean;
    quantity: number;
}

export function emptyPackaging(): Packaging {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        dimensionsUnit: "cm",
        weightUnit: "kg",
        isTube: false,
        quantity: 0
    };
}
