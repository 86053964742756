




























































































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import Modal from "@/components/Modal.vue";
import {emptyParcel, Parcel, ParcelApi} from "@/api/v1/ParcelApi";
import {OrderApi, OrderItem} from "@/api/v1/OrderApi";
import {Action} from "vuex-class";
import WeightInputGroup from "@/components/helpers/WeightInputGroup.vue";
import DimensionsInputGroup from "@/components/helpers/DimensionsInputGroup.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {emptyPackaging, Packaging} from "@/api/v1/PackagingApi";
import Check from "@/components/helpers/Check.vue";
import {ErrorToastSettings, FilterOperand, IFilter, SuccessToastSettings} from "@/api/Utils";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {Check, SelectionTable, DimensionsInputGroup, WeightInputGroup, Modal}})
export default class CreateParcel extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;

    parcel: Parcel = emptyParcel();
    orderItems: OrderItem[] = [];

    selectedPackaging: Packaging[] = [];
    packaging: Packaging = emptyPackaging();
    canShowError = false;

    @Action invalidateParcels!: () => any;

    get packageSelectionColumns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("dimensions.text"), key: "dimensions", sortable: false},
            {label: this.$t("weight"), key: "weight", sortable: false},
            {label: this.$t("is-tube"), key: "isTube", sortable: true},
            {label: this.$t("quantity"), key: "quantity", sortable: true}
        ];
    }

    //TODO add to these filters
    get packageSelectionFilters(): IFilter[] {
        const items = this.parcel.items.filter(i => i.quantity > 0);
        if (this.orderItems.length == 0 || items.length == 0) return [];
        return [
            {
                name: "length",
                op: FilterOperand.GreaterThanOrEquals,
                value: items.map(i => i.length).reduce((a, b) => Math.max(a, b))
            }
        ];
    }

    get validatePackaging(): boolean {
        return this.packaging.id.toString() !== Guid.EMPTY;
    }

    get validateContents(): boolean {
        return this.parcel.items.some(i => i.quantity > 0);
    }

    updateQuantity(index: number, value: number) {
        this.parcel.items[index].quantity = value;
        this.clearPackaging();
    }

    done() {
        this.$emit("done");
    }

    submit() {
        let packagingId: Guid | null = null;
        if (this.settingsCheck("packaging") && this.validatePackaging) {
            packagingId = this.packaging.id;
        }
        if (this.validateContents) {
            ParcelApi.create(this.clientId, this.orderId, this.parcel, packagingId)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("parcel-created").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidateParcels();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-create-parcel").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    @Watch("selectedPackaging")
    onSelectedPackagingUpdate() {
        if (this.selectedPackaging.length > 0) {
            this.packaging = this.selectedPackaging[0];
            this.parcel.length = this.packaging.length;
            this.parcel.width = this.packaging.width;
            this.parcel.height = this.packaging.height;
            this.parcel.dimensionsUnit = this.packaging.dimensionsUnit;
            this.parcel.weight = this.packaging.weight;
            this.parcel.weightUnit = this.packaging.weightUnit;
            this.parcel.isTube = this.packaging.isTube;
        }
    }

    clearPackaging() {
        this.packaging = emptyPackaging();
    }

    created() {
        OrderApi.findOrderItemsById(this.clientId, this.orderId)
            .then(data => {
                this.orderItems = data;
                data.forEach(d => {
                    this.parcel.items.push({...d});
                });
                this.canShowError = true;
            });
    }
}
