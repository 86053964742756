




























import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {emptyPaginatedList, FilteredQueryRequest, IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {Guid} from "guid-typescript";
import {ProductVariation, ProductVariationApi} from "@/api/v1/ProductVariationApi";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import Helper from "@/components/helpers/Helper.vue";

@Component({components: {DataTable}})
export default class ProductVariationTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly productId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<ProductVariation>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest(1, 25, "id");

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("stock-level"), key: "stockLevel", sortable: true},
            {label: "SKU", key: "sku", sortable: true},
            {label: "EAN", key: "ean", sortable: true},
        ];
    }

    @Watch("tstpCache.productVariations", {immediate: true})
    refresh() {
        this.dataProvider = ProductVariationApi.find(this.clientId, this.productId, this.query)
            .then(data => data);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }
}
