


















import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import Check from "@/components/helpers/Check.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
} from "@/api/Utils";
import {LegacyProductApi, LegacyProduct} from "@/api/v1/LegacyProductApi";
import {Getter} from "vuex-class";
import {Guid} from "guid-typescript";
import {TimestampCache} from "@/store/cache";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {
        DataTable,
        Check,
    }
})
export default class ProductTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<LegacyProduct>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("active"), key: "active", sortable: true},
            {label: "SKU", key: "sku", sortable: true},
        ];
    }

    @Watch("tstpCache.products")
    refresh() {
        this.dataProvider = LegacyProductApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }
}
