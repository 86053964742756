












































































































































































































































































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {emptyParcel, Label, LabelType, Parcel, ParcelApi} from "@/api/v1/ParcelApi";
import Status from "@/components/helpers/Status.vue";
import Check from "@/components/helpers/Check.vue";
import Icon from "@/components/helpers/Icon.vue";
import IconButton from "@/components/helpers/IconButton.vue";
import {emptyTemplate, Template, TemplateType} from "@/api/v1/TemplateApi";
import {displayDateTime, ErrorToastSettings, FilterOperand, IFilter, SuccessToastSettings} from "@/api/Utils";
import SelectionTable from "@/components/SelectionTable.vue";
import NotFound from "@/views/NotFound.vue";
import {TimestampCache} from "@/store/cache";
import {Getter} from "vuex-class";
import {openBase64InNewTab, ParcelShippingApi} from "@/api/v1/ParcelShippingApi";
import {emptyOrderWithAddress, OrderApi, OrderStatus, OrderWithAddress} from "@/api/v1/OrderApi";
import {displayCountry} from "@/api/CountryApi";
import {CarrierType} from "@/api/v1/CarrierApi";
import Modal from "@/components/Modal.vue";
import Helper from "@/components/helpers/Helper.vue";
import {P2GParcelShippingApi} from "@/api/v1/P2GParcelShippingApi";
import {RMCDParcelShippingApi} from "@/api/v1/RMCDParcelShippingApi";

@Component({components: {NotFound, IconButton, Icon, Status, Check, SelectionTable, Modal}})
export default class ParcelOverview extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly parcelId!: Guid;

    CarrierType: typeof CarrierType = CarrierType;

    parcel: Parcel = emptyParcel();
    order: OrderWithAddress = emptyOrderWithAddress();
    orderSheet: Template = emptyTemplate(TemplateType.OrderSheet);
    labels: Label[] = [];
    loadingLabels = false;
    loadingLabel = false;

    notFound = false;
    deleting = false;
    trying = false;
    errorDispatching = false;

    @Getter tstpCache!: TimestampCache;

    get isTransglobal(): boolean {
        return this.parcel.carrierType === CarrierType.TransglobalExpress;
    }

    get isClickAndDrop(): boolean {
        return this.parcel.carrierType === CarrierType.ClickAndDrop;
    }

    get orderSheetTemplateSelectionFilters(): IFilter[] {
        return [
            {name: "type", op: FilterOperand.Equals, value: TemplateType.OrderSheet}
        ];
    }

    get showDocumentsTable() {
        return this.parcel.status !== OrderStatus.Outstanding;
    }

    get dimensions(): string {
        return `${this.parcel.length} X ${this.parcel.width} X ${this.parcel.height} ${this.parcel.dimensionsUnit}`;
    }

    displayDateTime(date: string | Date): string {
        return displayDateTime(date);
    }

    updateParcel() {
        this.$router.push({
            name: "updateParcel",
            params: {orderId: this.orderId.toString(), parcelId: this.parcelId.toString()}
        });
    }

    @Watch("tstpCache.parcel")
    refresh() {
        ParcelApi.findById(this.clientId, this.orderId, this.parcelId)
            .then(data => {
                this.parcel = data;
            })
            .catch(err => {
                if (err.status === 404) {
                    this.notFound = true;
                } else {
                    this.$bvToast.toast(this.$t("failed-retrieve-parcel").toString(), ErrorToastSettings);

                    throw err;
                }
            });
    }

    async printLabel(label: Label) {
        this.loadingLabel = true;
        try {
            await openBase64InNewTab(label.content, label.format, this.parcelId.toString());
        } finally {
            this.loadingLabel = false;
        }
    }

    getTrackingNumber() {
        if (this.parcel.carrierType === CarrierType.ClickAndDrop) {
            RMCDParcelShippingApi.updateTrackingNumberFromSource(this.clientId, this.parcelId)
                .then(() => {
                   this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast("Failed to get tracking number", ErrorToastSettings);

                    throw err;
                });
        }
    }

    retryLabel() {
        this.trying = true;
        P2GParcelShippingApi.updateLabelFromSource(this.clientId, this.parcelId)
            .then(() => {
                this.trying = false;
                this.refresh();
            })
            .catch(err => {
                this.trying = false;
                this.$bvToast.toast(this.$t("failed-load-label").toString(), ErrorToastSettings);

                throw err;
            });
    }

    getLabelType(label: Label): string {
        if (label.type === LabelType.Primary) return "Label - Primary";
        else if (label.type === LabelType.Collection) return "Label - Collection";
        else if (label.type === LabelType.Document) return "Document" + " - " + label.reference;
        return "Unknown";
    }

    ship() {
        this.$router.push({
            name: "ship",
            params: {
                clientId: this.parcel.clientId.toString(),
                orderId: this.parcel.orderId.toString(),
                parcelId: this.parcel.id.toString()
            }
        });
    }

    saveOrderSheet() {
        ParcelApi.useOrderSheet(this.clientId, this.orderId, this.parcelId, this.orderSheet.id)
            .then(() => {
                this.$bvToast.toast(this.$t("order-sheet-saved").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-save-order-sheet").toString(), ErrorToastSettings);

                throw err;
            });
    }

    clearOrderSheet() {
        this.orderSheet = emptyTemplate(TemplateType.OrderSheet);
    }

    deleteParcel() {
        ParcelApi.delete(this.clientId, this.orderId, this.parcelId)
            .then(() => {
                this.deleting = false;
                this.$bvToast.toast(this.$t("parcel-deleted").toString(), SuccessToastSettings);
                this.$router.push({name: "orderOverview", params: {orderId: this.orderId.toString()}});
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-parcel").toString(), ErrorToastSettings);

                throw err;
            });
    }

    country(country: string): string {
        return displayCountry(country);
    }

    async dispatch() {
        this.errorDispatching = false;
        try {
            if (this.parcel.carrierType === CarrierType.ClickAndDrop && !this.parcel.trackingNumber) {
                await RMCDParcelShippingApi.updateTrackingNumberFromSource(this.clientId, this.parcelId);
            }
            await ParcelApi.markDispatched(this.clientId, this.orderId, this.parcelId);
            this.$bvToast.toast(this.$t("dispatched").toString(), SuccessToastSettings);
            this.refresh();
        } catch (err) {
            this.errorDispatching = true;

            throw err;
        }
    }

    forceDispatch() {
        this.errorDispatching = false;
        ParcelApi.markDispatched(this.clientId, this.orderId, this.parcelId, true)
            .then(() => {
                this.$bvToast.toast(this.$t("dispatched").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-dispatch").toString(), ErrorToastSettings);

                throw err;
            });
    }

    created() {
        OrderApi.findWithAddressById(this.clientId, this.orderId)
            .then(data => {
                this.order = data;

                if (this.order.status !== OrderStatus.Outstanding) {
                    this.loadingLabels = true;
                    ParcelApi.findLabels(this.clientId, this.orderId, this.parcelId)
                        .then(labels => {
                            this.labels = labels;
                            this.loadingLabels = false;
                        })
                        .catch(err => {
                            this.$parent.$bvToast.toast(this.$t("failed-to-retrieve-labels").toString(), ErrorToastSettings);

                            this.loadingLabels = false;
                            throw err;
                        });
                }
            })
            .catch(err => {
                if (err.status === 404) {
                    this.$router.push({name: "ordersTable"});
                } else {
                    this.$parent.$bvToast.toast(this.$t("failed-retrieve-order").toString(), ErrorToastSettings);

                    throw err;
                }
            });

        this.refresh();
    }
}
