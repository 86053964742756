var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('data-table',{attrs:{"title":_vm.$t('packaging'),"columns":_vm.columns,"data-provider":_vm.dataProvider,"show-plus":_vm.permissionCheck('packaging:create')},on:{"query-change":_vm.queryChange,"create":_vm.create},scopedSlots:_vm._u([{key:"filters",fn:function(){return [_c('div',{staticClass:"d-inline-block w-20 w-xs-100"},[_c('label',[_vm._v(_vm._s(_vm.$t("tube-filter")))]),_c('select',{directives:[{name:"model",rawName:"v-model",value:(_vm.tubeFilterValue),expression:"tubeFilterValue"}],staticClass:"form-control custom-select",on:{"change":function($event){var $$selectedVal = Array.prototype.filter.call($event.target.options,function(o){return o.selected}).map(function(o){var val = "_value" in o ? o._value : o.value;return val}); _vm.tubeFilterValue=$event.target.multiple ? $$selectedVal : $$selectedVal[0]}}},[_c('option',{domProps:{"value":null}},[_vm._v(_vm._s(_vm.$t("no-selection")))]),_c('option',{domProps:{"value":true}},[_vm._v("Is Tube")]),_c('option',{domProps:{"value":false}},[_vm._v("Not Tube")])])])]},proxy:true},{key:"length",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.length)+" "+_vm._s(item.dimensionsUnit)+" ")]}},{key:"width",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.width)+" "+_vm._s(item.dimensionsUnit)+" ")]}},{key:"height",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.height)+" "+_vm._s(item.dimensionsUnit)+" ")]}},{key:"weight",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.weight)+" "+_vm._s(item.weightUnit)+" ")]}},{key:"rowActions",fn:function(ref){
var item = ref.item;
return [_c('div',{staticClass:"float-right"},[(_vm.permissionCheck('packaging:update'))?_c('update-icon',{attrs:{"to":{name: 'updatePackaging', params: {clientId: _vm.clientId.toString(), packagingId: item.id.toString()}}}}):_vm._e(),(_vm.permissionCheck('packaging:delete'))?_c('delete-icon',{on:{"click":function($event){return _vm.confirmDelete(item)}}}):_vm._e()],1)]}}])}),(_vm.deleting)?_c('modal',{attrs:{"title":_vm.$t('delete-packaging')},on:{"done":_vm.closeModal},scopedSlots:_vm._u([{key:"footer",fn:function(){return [_c('button',{staticClass:"btn btn-secondary",attrs:{"type":"button"},on:{"click":_vm.closeModal}},[_vm._v(_vm._s(_vm.$t("cancel")))]),_c('button',{staticClass:"btn btn-danger",attrs:{"type":"button"},on:{"click":_vm.deletePackaging}},[_vm._v(_vm._s(_vm.$t("delete")))])]},proxy:true}],null,false,3407347705)},[_vm._v(" Are you sure you want to delete "+_vm._s(_vm.deleting.name)+"? ")]):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }