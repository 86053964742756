














































































import {Component, Prop, Vue} from "vue-property-decorator";
import Modal from "@/components/Modal.vue";
import {Action} from "vuex-class";
import {Guid} from "guid-typescript";
import {emptyProduct, Product, ProductApi} from "@/api/v1/ProductApi";
import DimensionsInputGroup from "@/components/helpers/DimensionsInputGroup.vue";
import WeightInputGroup from "@/components/helpers/WeightInputGroup.vue";
import {canScanBarcode} from "@/api/BarcodeDetection";
import Icon from "@/components/helpers/Icon.vue";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";

const BarcodeScanner = () => import(/* webpackChunkName: "barcode" */ "@/components/BarcodeScanner.vue");

@Component({components: {CountrySelect, BarcodeScanner, WeightInputGroup, DimensionsInputGroup, Modal, Icon}})
export default class CreateOrUpdateProduct extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly productId!: Guid | null;

    @Action invalidateProducts!: () => any;

    product: Product = emptyProduct();
    barcodeScanSupported = false;
    response: any = null;
    showBarcodeScanner = false;

    get validEan(): boolean | null {
        if (!this.product.ean) return null;

        const gtin = parseInt(this.product.ean, 10).toString();
        const toInt = numString => {
            return parseInt(numString, 10);
        };

        const chunks = gtin.split("").map(toInt).reverse();
        let checksum = 0;

        // Remove first chuck (checksum)
        chunks.shift();

        // sum numbers and multiply accordingly
        chunks.forEach(function( number, index ) {
            checksum += (index % 2) === 1 ? number : number*3;
        });

        // calc checksum
        checksum %= 10;
        checksum = (checksum === 0) ? 0 : (10 - checksum);

        const actualVal = parseInt(this.product.ean.substring(this.product.ean.length - 1), 10);
        return actualVal === checksum;
    }

    scanBarcode() {
        if (this.barcodeScanSupported)
            this.showBarcodeScanner = true;
    }

    done() {
        this.$emit("done");
    }

    submit() {
        const valid = (this.validEan === null || this.validEan);

        if (valid) {
            if (this.productId) {
                ProductApi.update(this.clientId, this.productId, this.product)
                    .then(() => {
                        this.$parent.$bvToast.toast(this.$t("product-updated").toString(), SuccessToastSettings);
                        this.done();
                        this.invalidateProducts();
                    })
                    .catch(err => {
                        this.$parent.$bvToast.toast(this.$t("failed-update-product").toString(), ErrorToastSettings);

                        throw err;
                    });
            } else {
                ProductApi.create(this.clientId, this.product)
                    .then(() => {
                        this.$parent.$bvToast.toast(this.$t("product-created").toString(), SuccessToastSettings);
                        this.done();
                        this.invalidateProducts();
                    })
                    .catch(err => {
                        this.$parent.$bvToast.toast(this.$t("failed-create-product").toString(), ErrorToastSettings);

                        throw err;
                    });
            }
        }
    }

    barcodeScanned(value: string | null) {
        this.showBarcodeScanner = false;
        this.product.ean = value;
    }

    async created() {
        this.barcodeScanSupported = (await canScanBarcode() && window.matchMedia("(hover: none)").matches);

        if (this.productId) {
            ProductApi.findById(this.clientId, this.productId)
                .then(data => {
                    this.product = data;
                    this.product.weightUnit ||= "kg";
                    this.product.dimensionsUnit ||= "cm";
                });
        }
    }
}
