





















import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class WeightInputGroup extends Vue {
    @Prop({type: Number}) readonly weight!: number;
    @Prop({type: String}) readonly units!: string;
    @Prop({type: String}) readonly label!: string;

    updateWeight(e) {
        this.$emit("update:weight", (e.target as HTMLInputElement).valueAsNumber);
    }

    updateUnits(e) {
        this.$emit("update:units", (e.target as HTMLInputElement).value);
    }
}
