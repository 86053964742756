import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {getRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const LegacyProductApi = {
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<LegacyProduct>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<LegacyProduct> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/legacy-products`;
}

export interface LegacyProduct {
    clientId: Guid;
    id: Guid;
    name: string;
    active: boolean;
    sku: string;
}


export function emptyLegacyProduct(): LegacyProduct {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        active: false,
        sku: "",
    };
}
