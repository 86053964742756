



























































































































































































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {emptyOrderWithAddress, OrderApi, OrderWithAddress} from "@/api/v1/OrderApi";
import Icon from "@/components/helpers/Icon.vue";
import ParcelCard from "@/views/orders/ParcelCard.vue";
import {Getter} from "vuex-class";
import {Parcel, ParcelApi} from "@/api/v1/ParcelApi";
import {
    displayDateTime,
    displayDate,
    emptyPaginatedList,
    ErrorToastSettings,
    IPaginatedList,
    QueryRequest,
    SuccessToastSettings
} from "@/api/Utils";
import {TimestampCache} from "@/store/cache";
import IconButton from "@/components/helpers/IconButton.vue";
import Status from "@/components/helpers/Status.vue";
import {displayCountry} from "@/api/CountryApi";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import NotFound from "@/views/NotFound.vue";
import OrderFilesTable from "@/components/orders/OrderFilesTable.vue";
import Modal from "@/components/Modal.vue";
import Helper from "@/components/helpers/Helper.vue";
import Check from "@/components/helpers/Check.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import OrderItemTable from "@/views/orders/OrderItemTable.vue";
import OrderUserAssignmentTable from "@/views/orders/user-assignment/OrderUserAssignmentTable.vue";

@Component({
    components: {
        OrderUserAssignmentTable,
        OrderItemTable,
        DeleteIcon,
        OrderFilesTable,
        NotFound,
        UpdateIcon,
        Status,
        ParcelCard,
        Icon,
        IconButton,
        Modal,
        Check
    }
})
export default class OrderOverview extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;

    @Getter tstpCache!: TimestampCache;

    order: OrderWithAddress = emptyOrderWithAddress();
    parcels: IPaginatedList<Parcel> = emptyPaginatedList();

    notFound = false;
    deleting = false;

    get extraData(): object | null {
        return (this.order.extraData ?? null) === null ? null : JSON.parse(this.order.extraData);
    }

    get sortedParcels(): Parcel[] {
        // @ts-ignore
        return this.parcels.list.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt));
    }

    country(country: string): string {
        return displayCountry(country);
    }

    displayDateTime(date: string | Date): string {
        return displayDateTime(date);
    }

    displayDate(date: string | Date): string {
        return displayDate(date);
    }

    @Watch("tstpCache.order")
    refresh() {
        OrderApi.findWithAddressById(this.clientId, this.orderId)
            .then(data => {
                this.order = data;
            })
            .catch(err => {
                if (err.status === 404) {
                    this.notFound = true;
                } else {
                    this.$bvToast.toast(this.$t("failed-retrieve-order").toString(), ErrorToastSettings);
                    throw err;
                }
            });
    }

    @Watch("tstpCache.parcels", {immediate: true})
    refreshParcels() {
        this.refresh();
        ParcelApi.find(this.clientId, this.orderId, new QueryRequest(1, 20, "id"))
            .then(data => this.parcels = data);
    }

    updateOrderInformation() {
        this.$router.push({name: "updateOrder", params: {orderId: this.orderId.toString()}});
    }

    createParcel() {
        this.$router.push({
            name: "createParcel",
            params: {clientId: this.clientId.toString(), orderId: this.orderId.toString()}
        });
    }

    deleteOrder() {
        OrderApi.delete(this.clientId, this.orderId)
            .then(() => {
                this.deleting = false;
                this.$bvToast.toast(this.$t("order-deleted").toString(), SuccessToastSettings);
                this.$router.push({name: "orders", params: {orderId: this.orderId.toString()}});
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-order").toString(), ErrorToastSettings);

                throw err;
            });
    }

    mounted() {
        this.refresh();
    }
}
