


































































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import {Guid} from "guid-typescript";
import {TimestampCache} from "@/store/cache";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    FilterOperand,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Getter} from "vuex-class";
import {Packaging, PackagingApi} from "@/api/v1/PackagingApi";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import Check from "@/components/helpers/Check.vue";
import Helper from "@/components/helpers/Helper.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Modal from "@/components/Modal.vue";

@Component({components: {Modal, Check, UpdateIcon, DeleteIcon, DataTable}})
export default class PackagingTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<Packaging>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();
    tubeFilterValue: boolean | null = null;
    deleting: Packaging | null = null;

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: this.$t("dimensions.length"), key: "length", sortable: true},
            {label: this.$t("dimensions.width"), key: "width", sortable: true},
            {label: this.$t("dimensions.height"), key: "height", sortable: true},
            {label: this.$t("weight"), key: "weight", sortable: true},
            {label: this.$t("tube"), key: "isTube", sortable: true},
            {label: this.$t("quantity"), key: "quantity", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tubeFilterValue")
    onTubeFilterUpdate() {
        const tubeFilter = this.query.filters.find(f => f.name === "isTube");
        if (tubeFilter) {
            if (this.tubeFilterValue === null) {
                this.query.filters = this.query.filters.filter(f => f.name !== "isTube");
            }
            else tubeFilter.value = this.tubeFilterValue;
        }

        this.refresh();
    }

    @Watch("tstpCache.packaging")
    refresh() {
        if (this.query.filters.find(f => f.name === "isTube") === undefined && this.tubeFilterValue !== null) {
            this.query.filters.push({
                name: "isTube",
                op: FilterOperand.Equals,
                value: this.tubeFilterValue
            });
        }
        this.dataProvider = PackagingApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    create() {
        this.$router.push({name: "createPackaging"});
    }

    confirmDelete(item: Packaging) {
        this.deleting = item;
    }

    deletePackaging() {
        if (this.deleting) {
            PackagingApi.delete(this.clientId, this.deleting.id)
                .then(() => {
                    this.$bvToast.toast(this.$t("packaging-deleted").toString(), SuccessToastSettings);
                    this.deleting = null;
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-delete-packaging").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    closeModal() {
        this.deleting = null;
    }
}
