






























































































import {Component, Prop} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {OrderFilesApi} from "@/api/v1/OrderFilesApi";
import IconButton from "@/components/helpers/IconButton.vue";
import Modal from "@/components/Modal.vue";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import Icon from "@/components/helpers/Icon.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {Icon, DeleteIcon, IconButton, Modal}
})
export default class OrderFilesTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;

    files: string[] = [];

    shouldShowModal = false;
    deleting: string | null = null;

    filesToUpload: File[] = [];

    get fileLimitReached(): boolean {
        return this.files.length >= 30;
    }

    get canUpload(): boolean {
        return (this.files.length + this.filesToUpload.length) <= 30;
    }

    onFileUpload(e: any) {
        const files = (e.target as HTMLInputElement).files;

        if (files) {
            this.filesToUpload = Array.from(files);
        }
    }

    showModal() {
        if (!this.fileLimitReached)
            this.shouldShowModal = true;
    }

    getHref(name?: string): string {
        if (name)
            return OrderFilesApi.downloadHref(this.clientId, this.orderId, name);
        else
            return OrderFilesApi.downloadAllHref(this.clientId, this.orderId);
    }

    upload() {
        if (this.filesToUpload.length && this.canUpload) {
            OrderFilesApi.store(this.clientId, this.orderId, this.filesToUpload)
                .then(() => {
                    this.shouldShowModal = false;
                    this.filesToUpload = [];
                    this.refresh();
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-upload-files").toString(), ErrorToastSettings);
                    this.filesToUpload = [];

                    throw err;
                });
        }
    }

    deleteFile(name: string) {
        this.deleting = null;
        OrderFilesApi.delete(this.clientId, this.orderId, name)
            .then(() => {
                this.$bvToast.toast(this.$t("file-deleted").toString(), SuccessToastSettings);
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-delete-file").toString(), ErrorToastSettings);

                throw err;
            });
    }

    refresh() {
        OrderFilesApi.list(this.clientId, this.orderId)
            .then(data => {
                this.files = data;
            })
            .catch(err => {
                this.$bvToast.toast(this.$t("failed-retrieve-files").toString(), ErrorToastSettings);

                throw err;
            });
    }

    created() {
        this.refresh();
    }
}
