












































import {Vue, Component, Prop} from "vue-property-decorator";

@Component
export default class DimensionsInputGroup extends Vue {
    @Prop({type: Number}) readonly length!: number;
    @Prop({type: Number}) readonly width!: number;
    @Prop({type: Number}) readonly height!: number;
    @Prop({type: String}) readonly units!: string;
    @Prop({type: Boolean, default: false}) readonly required!: boolean;

    updateLength(e) {
        this.$emit("update:length", (e.target as HTMLInputElement).valueAsNumber);
    }

    updateWidth(e) {
        this.$emit("update:width", (e.target as HTMLInputElement).valueAsNumber);
    }

    updateHeight(e) {
        this.$emit("update:height", (e.target as HTMLInputElement).valueAsNumber);
    }

    updateUnits(e) {
        this.$emit("update:units", (e.target as HTMLInputElement).value);
    }
}
