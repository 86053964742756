




















































import {Component, Prop, Vue} from "vue-property-decorator";
import {Parcel} from "@/api/v1/ParcelApi";
import Status from "@/components/helpers/Status.vue";
import Icon from "@/components/helpers/Icon.vue";
import Check from "@/components/helpers/Check.vue";
import IconButton from "@/components/helpers/IconButton.vue";

//TODO remove duplicate code between this and ParcelOverview.vue
@Component({components: {Status, Icon, Check, IconButton}})
export default class ParcelCard extends Vue {
    @Prop({type: Object, required: true}) readonly parcel!: Parcel;

    get carrierText(): string {
        return this.parcel.carrier ?? "N/A";
    }

    get dimensions(): string {
        return `${this.parcel.length} X ${this.parcel.width} X ${this.parcel.height} ${this.parcel.dimensionsUnit}`;
    }

    get view(): any {
        return {
            name: "parcelOverview",
            params: {clientId: this.parcel.clientId.toString(), orderId: this.parcel.orderId.toString(), parcelId: this.parcel.id.toString()}
        };
    }

    ship() {
        this.$router.push({
            name: "ship",
            params: {clientId: this.parcel.clientId.toString(), orderId: this.parcel.orderId.toString(), parcelId: this.parcel.id.toString()}
        });
    }
}
