











import {Component, Model, Prop, Vue} from "vue-property-decorator";
import {CarrierType} from "@/api/v1/CarrierApi";

@Component
export default class TrackingNumberInput extends Vue {
    @Model("update:value", {type: String, default: ""}) value!: string;
    @Prop({type: Boolean, default: false}) required!: boolean;
    @Prop({type: Number, required: false}) carrierType!: CarrierType;

    get trackingNumber(): string {
        return this.value;
    }

    set trackingNumber(value: string) {
        this.$emit("update:value", value.toUpperCase());
    }
}
