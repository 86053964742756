
















































import {Component, Prop, Watch} from "vue-property-decorator";
import DataTable from "@/components/DataTable.vue";
import Check from "@/components/helpers/Check.vue";
import {ITableColumn} from "@/components/helpers/ITableColumn";
import {
    emptyPaginatedList,
    ErrorToastSettings,
    FilteredQueryRequest,
    IFilteredQueryRequest,
    IPaginatedList,
    SuccessToastSettings
} from "@/api/Utils";
import {Product, ProductApi} from "@/api/v1/ProductApi";
import {Getter} from "vuex-class";
import Modal from "@/components/Modal.vue";
import {Guid} from "guid-typescript";
import IconButton from "@/components/helpers/IconButton.vue";
import Icon from "@/components/helpers/Icon.vue";
import {TimestampCache} from "@/store/cache";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import Helper from "@/components/helpers/Helper.vue";

@Component({
    components: {
        UpdateIcon,
        DeleteIcon,
        IconButton,
        Icon,
        DataTable,
        Check,
        Modal
    }
})
export default class ProductTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter tstpCache!: TimestampCache;

    dataProvider: Promise<IPaginatedList<Product>> = Promise.resolve(emptyPaginatedList());
    query: IFilteredQueryRequest = new FilteredQueryRequest();

    deleting: Product | null = null;
    selectedProducts: any[] = [];

    get columns(): ITableColumn[] {
        return [
            {label: this.$t("name"), key: "name", sortable: true},
            {label: "SKU", key: "sku", sortable: true},
            {label: "EAN", key: "ean", sortable: true},
            {label: "Stock Level", key: "stockLevel", sortable: true},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    @Watch("tstpCache.products")
    refresh() {
        this.dataProvider = ProductApi.find(this.clientId, this.query);
    }

    queryChange(query: IFilteredQueryRequest) {
        this.query = query;
        this.refresh();
    }

    confirmDelete(item: Product) {
        this.deleting = item;
    }

    deleteSelected() {
        if (this.deleting) {
            ProductApi.delete(this.clientId, this.deleting.id)
                .then(() => {
                    this.$bvToast.toast(this.$t("product-deleted").toString(), SuccessToastSettings);
                    this.selectedProducts = [];
                    this.refresh();
                    this.deleting = null;
                })
                .catch(err => {
                    this.$bvToast.toast(this.$t("failed-delete-product").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    closeModal() {
        this.deleting = null;
    }

    create() {
        this.$router.push({name: "createProduct"});
    }
}
