


















































































































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import {NewOrder, OrderApi, validateAddress, validateOrder} from "@/api/v1/OrderApi";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Icon from "@/components/helpers/Icon.vue";
import RequestErrorAlert from "@/components/RequestErrorAlert.vue";
import {emptyAddress} from "@/api/v1/AddressApi";
import DateInput from "@/components/DateInput.vue";
import {formatISO} from "date-fns";
import Tabs from "@/components/Tabs.vue";
import {ClientShippingInfoApi} from "@/api/v1/ClientShippingInfoApi";

@Component({
    components: {Tabs, DateInput, RequestErrorAlert, CountrySelect, Icon}
})
export default class CreateOrder extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    errors: any = {};

    order: NewOrder = {
        clientId: this.clientId,
        currencyCode: "GBP",
        sourceId: "",
        orderSourceName: "Manual",
        orderSourceType: OrderSourceType.Manual,
        status: 0,
        buyerName: "",
        buyerEmail: "",
        shippingPrice: 0,
        discountAmount: 0,
        deliveryAddress: emptyAddress(),
        placedAt: new Date() as any,
        hasFiles: false,
        repeatCustomer: false,
        iossNumber: undefined,
    };

    shippingPrice = 0;

    get validOrderDetails(): boolean {
        return validateOrder(this.order);
    }

    get validDeliveryAddress(): boolean {
        return validateAddress(this.order.deliveryAddress);
    }

    get today(): string {
        return formatISO(new Date(), {representation: "date"});
    }

    @Watch("shippingPrice")
    onShippingPriceChange() {
        this.order.shippingPrice = this.shippingPrice * 100;
    }

    createOrder() {
        if (this.validOrderDetails && this.validDeliveryAddress) {
            OrderApi.create(this.clientId, this.order)
                .then((order) => {
                    this.$parent.$bvToast.toast(this.$t("order-created").toString(), SuccessToastSettings);
                    // eslint-disable-next-line
                    this.$router.push({name: "orderOverview", params: {orderId: order.id!.toString()}});
                })
                .catch(err => {
                    if (err.status === 400) {
                        this.errors = err.data.errors;
                    } else {
                        this.$parent.$bvToast.toast(this.$t("failed-create-order").toString(), ErrorToastSettings);

                        throw err;
                    }
                });
        }
    }

    mounted() {
        ClientShippingInfoApi.get(this.clientId)
            .then(data => {
                if (data?.iossNumber) {
                    this.order.iossNumber = data.iossNumber;
                }
            });
    }
}
