













































import {Vue, Component, Prop} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import Modal from "@/components/Modal.vue";
import {emptyPackaging, Packaging, PackagingApi} from "@/api/v1/PackagingApi";
import {Action} from "vuex-class";
import DimensionsInputGroup from "@/components/helpers/DimensionsInputGroup.vue";
import WeightInputGroup from "@/components/helpers/WeightInputGroup.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";

@Component({components: {WeightInputGroup, DimensionsInputGroup, Modal}})
export default class  CreateOrUpdatePackaging extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, default: null}) readonly packagingId!: Guid | null;

    @Action invalidatePackaging!: () => any;

    packaging: Packaging = emptyPackaging();

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.packagingId) {
            PackagingApi.update(this.clientId, this.packagingId, this.packaging)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("packaging-updated").toString(), SuccessToastSettings);
                    this.done();
                    this.invalidatePackaging();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-update-packaging").toString(), ErrorToastSettings);

                    throw err;
                });
        } else {
            PackagingApi.create(this.clientId, this.packaging)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("packaging-created").toString(), ErrorToastSettings);
                    this.done();
                    this.invalidatePackaging();
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-create-packaging").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    created() {
        if (this.packagingId) {
            PackagingApi.findById(this.clientId, this.packagingId)
                .then(data => {
                    if (data)
                        this.packaging = data;
                });
        }
    }
}
