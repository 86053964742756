import {Guid} from "guid-typescript";

export interface User {
    id: Guid;
    clientId: Guid;
    name: string;
    email: string;
    role: Role;
    activated: boolean;
    disabled: boolean;
    mfaEnabled: boolean;
    updatedAt: string;
    createdAt: string;
}

export enum Role {
    OrderSourceCreator = -2,
    Standard = 1,
    Manager = 10,
    Admin = 9001
}
