declare global {
    interface BarcodeDetector {
        detect(blah: ImageBitmapSource): Promise<{
            rawValue: string;
            format: string;
            cornerPoints: any[];
            boundingBox: DOMRectReadOnly;
        }[]>;
    }

    interface Window {
        BarcodeDetector?: {
            prototype: BarcodeDetector;
            new(options?: any): BarcodeDetector;
        };
    }
}

export async function canScanBarcode(): Promise<boolean> {
    if (window.BarcodeDetector) {
        return await new window.BarcodeDetector().detect(document.createElement("canvas"))
            .then(() => true)
            .catch((e: Error) => e.name !== "NotSupportedError");
    }

    return false;
}
