









import {Component, Prop, Vue} from "vue-property-decorator";

@Component
export default class RequestErrorAlert extends Vue {
    @Prop({type: String, required: true}) readonly title!: any;
    @Prop({type: Object, required: true}) readonly value!: any;

    get errors(): string[] {
        return Object.values(this.value).flat(2) as string[];
    }
}
