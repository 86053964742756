
































import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import Icon from "@/components/helpers/Icon.vue";
import RequestErrorAlert from "@/components/RequestErrorAlert.vue";
import Modal from "@/components/Modal.vue";
import {OrderApi} from "@/api/v1/OrderApi";
import {Action} from "vuex-class";

@Component({
    components: {Modal, RequestErrorAlert, CountrySelect, SelectionTable, Icon}
})
export default class UpdateOrderItem extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly itemId!: Guid;
    @Prop({type: Number, required: true}) readonly price!: number;
    @Prop({type: Number, required: true}) readonly quantity!: number;

    @Action invalidateOrderItems!: () => any;

    data: any = {
        price: 0,
        quantity: 0,
    };

    priceModel = 0;

    @Watch("priceModel", {immediate: true})
    onPriceChange() {
        this.data.price = Math.round(this.priceModel * 100);
    }

    done() {
        this.$emit("done");
    }

    submit() {
        OrderApi.updateItem(this.clientId, this.orderId, this.itemId, this.data)
            .then(() => {
                this.done();
                this.invalidateOrderItems();
            });
    }

    created() {
        this.priceModel = this.price / 100;
        this.data.quantity = this.quantity;
    }
}
