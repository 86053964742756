import {Guid} from "guid-typescript";
import {deleteRequest, getRequest, postRequest} from "@/api/HttpMethods";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";
import {User} from "@/api/v1/UserApi";

export const OrderUserAssignmentApi = {
    assignUserToOrder(clientId: Guid, orderId: Guid, userId: Guid): Promise<void> {
        return postRequest(`${getBaseUrl(clientId)}/${orderId}/users`, userId);
    },
    unassignUserFromOrder(clientId: Guid, orderId: Guid, userId: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${orderId}/users/${userId}`);
    },
    findUsersAssignedToOrder(clientId: Guid, orderId: Guid, query: IQueryRequest): Promise<IPaginatedList<User>> {
        return getRequest(`${getBaseUrl(clientId)}/${orderId}/users`, query);
    },
    assignOrdersToUser(clientId: Guid, userId: Guid, orderIds: Guid[]): Promise<void> {
        return postRequest(`/internal/api/v1/clients/${clientId}/users/${userId}/orders`, orderIds);
    },
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/orders`;
}
