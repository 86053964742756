import {Guid} from "guid-typescript";
import {getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";

export const P2GParcelShippingApi = {
    listQuotes(clientId: Guid, parcelId: Guid, carrierId: Guid, queryRequest: IQueryRequest): Promise<IPaginatedList<ShippingQuote>> {
        return getRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/quotes`, queryRequest);
    },
    buyPostage(clientId: Guid, parcelId: Guid, carrierId: Guid, quote: ShippingQuote, collectionDate: string | null = null): Promise<void> {
        return postRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/purchase`, {quote, collectionDate});
    },
    updateLabelFromSource(clientId: Guid, parcelId: Guid): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, parcelId)}/label-from-source`);
    },
};

function getBaseUrl(clientId: Guid, parcelId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/parcels/${parcelId}/shipping/parcel2go`;
}

export interface ShippingQuote {
    service: {
        courierName: string;
        name: string;
        slug: string;
        collectionType: string;
        deliveryType: string;
        links: {
            imageSmall: string;
            imagelarge: string;
            imageSvg: string;
            courier: string;
            service: string;
        };
        isPrinterRequired: boolean;
        shortDescriptions: string;
        overview: string;
        features: string;
        classification: string;
    };
    totalPrice: number;
    collectionDates: Array<{collectionDate: string; surcharge: number}>;
}
