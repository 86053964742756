

























import {Component, Prop, Vue, Watch} from "vue-property-decorator";
import {Getter} from "vuex-class";
import {Guid} from "guid-typescript";
import ProductVariationTable from "@/views/products/variations/ProductVariationTable.vue";
import {TimestampCache} from "@/store/cache";
import NotFound from "@/views/NotFound.vue";
import {ErrorToastSettings} from "@/api/Utils";
import {emptyLegacyProduct, LegacyProduct, LegacyProductApi} from "@/api/v1/LegacyProductApi";

@Component({
    components: {NotFound, ProductVariationTable}
})
export default class ManageLegacyProduct extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly productId!: Guid;

    @Getter tstpCache!: TimestampCache;

    product: LegacyProduct = emptyLegacyProduct();
    notFound = false;

    @Watch("tstpCache.products", {immediate: true})
    refresh() {
        LegacyProductApi.findById(this.clientId, this.productId)
            .then(data => {
                this.product = data;
            })
            .catch(err => {
                if (err.status === 404) {
                    this.notFound = true;
                } else {
                    this.$bvToast.toast(this.$t("failed-retrieve-product").toString(), ErrorToastSettings);

                    throw err;
                }
            });
    }

    get loaded(): boolean {
        return this.product.id.toString() !== Guid.EMPTY;
    }
}
