










































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import {OrderStatus} from "@/api/v1/OrderApi";
import SelectionTable from "@/components/SelectionTable.vue";
import Modal from "@/components/Modal.vue";
import {Action} from "vuex-class";
import {emptyParcel, Parcel, ParcelApi} from "@/api/v1/ParcelApi";
import TrackingNumberInput from "@/components/TrackingNumberInput.vue";
import {CarrierApi, CarrierType} from "@/api/v1/CarrierApi";
import DimensionsInputGroup from "@/components/helpers/DimensionsInputGroup.vue";
import WeightInputGroup from "@/components/helpers/WeightInputGroup.vue";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";

@Component({
    components: {
        WeightInputGroup,
        TrackingNumberInput,
        Modal,
        CountrySelect,
        SelectionTable,
        DimensionsInputGroup
    }
})
export default class UpdateParcel extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly parcelId!: Guid;

    parcel: Parcel = emptyParcel();
    carrierType: CarrierType | null = null;
    Status: typeof OrderStatus = OrderStatus;

    @Action invalidateParcel!: Function;

    get canUpdateCarrier(): boolean {
        return this.carrierType === CarrierType.AddressLabelOnly ||
            this.carrierType === CarrierType.Custom;
    }

    get canUpdateCarrierReference(): boolean {
        return this.carrierType === CarrierType.ClickAndDrop ||
            this.carrierType === CarrierType.AddressLabelOnly ||
            this.carrierType === CarrierType.Custom;
    }

    done() {
        this.$emit("done");
    }

    submit() {
        ParcelApi.update(this.clientId, this.orderId, this.parcelId, this.parcel)
            .then(() => {
                this.$parent.$bvToast.toast(this.$t("parcel-updated").toString(), SuccessToastSettings);
                this.done();
                this.invalidateParcel();
            })
            .catch(err => {
                this.$parent.$bvToast.toast(this.$t("failed-update-parcel").toString(), SuccessToastSettings);
                this.done();
                this.invalidateParcel();

                throw err;
            });
    }

    created() {
        ParcelApi.findById(this.clientId, this.orderId, this.parcelId)
            .then(data => {
                this.parcel = data;

                if (this.parcel.carrierAccountId && this.parcel.carrierAccountId.toString() !== Guid.EMPTY) {
                    CarrierApi.findById(this.clientId, this.parcel.carrierAccountId)
                        .then(data => {
                            this.carrierType = data.type;
                        })
                        .catch(err => {
                            //TODO improve error message
                            this.$parent.$bvToast.toast("Error", ErrorToastSettings);

                            throw err;
                        });
                }
            })
            .catch(err => {
                if (err.status === 404) {
                    this.done();
                }
                else {
                    this.$parent.$bvToast.toast(this.$t("failed-retrieve-parcel").toString(), ErrorToastSettings);

                    throw err;
                }
            });

    }
}
