import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Guid} from "guid-typescript";

export const ProductApi = {
    create(clientId: Guid, product: Product): Promise<Product> {
        return postRequest(getBaseUrl(clientId), product);
    },
    find(clientId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<Product>> {
        return getRequest(getBaseUrl(clientId), queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<Product> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    update(clientId: Guid, id: Guid, product: Product): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}`, product);
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    deleteMul(clientId: Guid, ids: Guid[]): Promise<void> {
        return deleteRequest(getBaseUrl(clientId), ids);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/products`;
}

export interface Product {
    clientId: Guid;
    id: Guid;
    name: string;
    sku: string;
    ean: string | null;
    stockLevel?: number;
    length?: number;
    width?: number;
    height?: number;
    dimensionsUnit: string;
    weight?: number;
    weightUnit: string;
    countryOfOrigin?: string;
    commodityCode?: string;
}

export function emptyProduct(): Product {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        name: "",
        weightUnit: "kg",
        dimensionsUnit: "cm",
        sku: "",
        ean: null
    };
}
