import {Guid} from "guid-typescript";
import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {getRequest} from "@/api/HttpMethods";

export const ProductVariationApi = {
    find(clientId: Guid, productId: Guid, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<ProductVariation>> {
        return getRequest(getBaseUrl(clientId, productId), queryRequest);
    },
    findById(clientId: Guid, productId: Guid, id: Guid): Promise<ProductVariation> {
        return getRequest(`${getBaseUrl(clientId, productId)}/${id}`);
    },
};

function getBaseUrl(clientId: Guid, productId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/legacy-products/${productId}/variations`;
}

export interface ProductVariation {
    id: Guid;
    productId: Guid;
    name: string;
    stockLevel?: number;
    length?: number;
    width?: number;
    height?: number;
    dimensionsUnit: string;
    weight?: number;
    weightUnit: string;
    tags: string[];
    sku: string;
    ean: string | null;
    countryOfOrigin?: string;
    commodityCode?: string;
}
