import {Guid} from "guid-typescript";
import {IFilteredQueryRequest, IPaginatedList} from "@/api/Utils";
import {deleteRequest, getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {Address, emptyAddress} from "@/api/v1/AddressApi";
import {Product} from "@/api/v1/ProductApi";
import {ProductVariation} from "@/api/v1/ProductVariationApi";
import {validateEmail} from "@/api/Validators";
import {OrderSourceType} from "@/api/v1/OrderSourceApi";
import {LegacyProduct} from "@/api/v1/LegacyProductApi";

export const OrderApi = {
    create(clientId: Guid, order: NewOrder): Promise<Order> {
        return postRequest(getBaseUrl(clientId), order);
    },
    find(clientId: Guid, assignedToMe: boolean, queryRequest: IFilteredQueryRequest): Promise<IPaginatedList<Order>> {
        return getRequest(getBaseUrl(clientId) + `?assignedToMe=${assignedToMe}`, queryRequest);
    },
    findById(clientId: Guid, id: Guid): Promise<Order> {
        return getRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    findWithAddressById(clientId: Guid, id: Guid): Promise<OrderWithAddress> {
        return getRequest(`${getBaseUrl(clientId)}/${id}?withAddress=true`);
    },
    updateInformation(clientId: Guid, id: Guid, buyerName: string, buyerEmail: string, notes: string | null | undefined,
        iossNumber: string | null | undefined, deliveryAddress: Address): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${id}/information`, {buyerName, buyerEmail, notes, iossNumber, deliveryAddress});
    },
    delete(clientId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${id}`);
    },
    createItem(clientId: Guid, id: Guid, data: NewOrderItem): Promise<void> {
        return postRequest(`${getBaseUrl(clientId)}/${id}/order-items`, data);
    },
    findOrderItemsById(clientId: Guid, id: Guid): Promise<OrderItem[]> {
        return getRequest(`${getBaseUrl(clientId)}/${id}/order-items`);
    },
    updateItem(clientId: Guid, orderId: Guid, id: Guid, data: any): Promise<void> {
        return putRequest(`${getBaseUrl(clientId)}/${orderId}/order-items/${id}`, data);
    },
    deleteItem(clientId: Guid, orderId: Guid, id: Guid): Promise<void> {
        return deleteRequest(`${getBaseUrl(clientId)}/${orderId}/order-items/${id}`);
    }
};

function getBaseUrl(clientId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/orders`;
}

export interface Order {
    clientId: Guid;
    id?: Guid;
    sourceId: string;
    orderSourceName: string;
    orderSourceType: OrderSourceType;
    status: OrderStatus;
    buyerName: string;
    buyerEmail: string;
    shippingPrice: number;
    discountAmount: number;
    placedAt: string;
    dispatchBy?: string;
    dispatchedAt?: string;
    notes?: string;
    sourceUrl?: string;
    shippingMethod?: string;
    extraData?: any;
    hasFiles: boolean;
    repeatCustomer: boolean;
    iossNumber?: string;
}

export interface OrderWithAddress extends Order{
    deliveryAddress: Address;
}

export interface OrderItem {
    id: Guid;
    name: string;
    sku: string;
    quantity: number;
    price: number;
    length: number;
    width: number;
    height: number;
    weight: number;
    productId: Guid;
    variationId: Guid;
}

export function emptyOrder(): Order {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        sourceId: "",
        orderSourceName: "Manual",
        orderSourceType: OrderSourceType.Manual,
        status: OrderStatus.Outstanding,
        buyerName: "",
        buyerEmail: "",
        discountAmount: 0,
        shippingPrice: 0,
        placedAt: "",
        hasFiles: false,
        repeatCustomer: false
    };
}

export function emptyOrderWithAddress(): OrderWithAddress {
    return {
        clientId: Guid.createEmpty(),
        id: Guid.createEmpty(),
        sourceId: "",
        orderSourceName: "Manual",
        orderSourceType: OrderSourceType.Manual,
        status: OrderStatus.Outstanding,
        buyerName: "",
        buyerEmail: "",
        discountAmount: 0,
        shippingPrice: 0,
        deliveryAddress: emptyAddress(),
        placedAt: "",
        hasFiles: false,
        repeatCustomer: false
    };
}

export function clamp(num: number, min: number, max: number): number {
    return Math.min(Math.max(num, min), max);
}

export function validateOrder(order: Order): boolean {
    return order.sourceId.length > 0 && order.buyerName?.length > 0 &&
        validateEmail(order.buyerEmail) && order.shippingPrice >= 0 && order.discountAmount >= 0;
}

export function validateAddress(address: Address) {
    return address.name.length > 0 && address.postcode.length > 0 &&
        address.lineOne.length > 0 && address.city.length > 0 && address.postcode.length > 0 && address.country.length > 0;
}

export interface NewOrderItem {
    product: Product | null;
    legacyProduct: LegacyProduct | null;
    variation: ProductVariation | null;
    quantity: number;
    price: number;
}

export interface NewOrder extends Order {
    currencyCode: string;
    deliveryAddress: Address;
}

export enum OrderStatus {
    Outstanding= 1,
    Processing = 2,
    Dispatched = 3
}
