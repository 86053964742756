



















































































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {NewOrderItem, OrderApi, OrderItem} from "@/api/v1/OrderApi";
import DeleteIcon from "@/components/helpers/DeleteIcon.vue";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import UpdateOrderItem from "@/views/orders/UpdateOrderItem.vue";
import UpdateIcon from "@/components/helpers/UpdateIcon.vue";
import Helper from "@/components/helpers/Helper.vue";
import AddOrderItem from "@/views/orders/AddOrderItem.vue";
import IconButton from "@/components/helpers/IconButton.vue";

@Component({components: {IconButton, AddOrderItem, UpdateIcon, UpdateOrderItem, DeleteIcon}})
export default class OrderItemTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: Boolean, required: true}) readonly readonly!: boolean;

    @Getter tstpCache!: TimestampCache;

    orderItems: OrderItem[] = [];

    loaded = false;

    itemToUpdate: any = {
        id: null,
        quantity: 0,
        price: 0,
    }

    showModal = false;

    get total(): number {
        let total = 0;
        this.orderItems.map(oi => oi.price * oi.quantity).forEach(val => total += val);
        return total;
    }

    @Watch("tstpCache.orderItems")
    refresh() {
        OrderApi.findOrderItemsById(this.clientId, this.orderId)
            .then(data => {
                this.orderItems = data;
                this.loaded = true;
            });
    }

    deleteItem(item: OrderItem) {
        OrderApi.deleteItem(this.clientId, this.orderId, item.id)
            .then(() => {
                this.refresh();
            });
    }

    createItem(item: NewOrderItem) {
        this.showModal = false;
        OrderApi.createItem(this.clientId, this.orderId, item)
            .then(() => {
                this.refresh();
            });
    }

    updateItem(item: OrderItem) {
        this.itemToUpdate.price = item.price;
        this.itemToUpdate.quantity = item.quantity;
        this.itemToUpdate.id = item.id;
    }

    itemUpdated() {
        this.itemToUpdate.id = null;
    }

    created() {
        this.refresh();
    }
}
