



























































































import {Component, Prop} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import {emptyOrderWithAddress, OrderApi, OrderWithAddress, validateAddress, validateOrder} from "@/api/v1/OrderApi";
import SelectionTable from "@/components/SelectionTable.vue";
import Modal from "@/components/Modal.vue";
import {Action} from "vuex-class";
import {ErrorToastSettings, SuccessToastSettings} from "@/api/Utils";
import Helper from "@/components/helpers/Helper.vue";
import Tabs from "@/components/Tabs.vue";

@Component({
    components: {Tabs, Modal, CountrySelect, SelectionTable}
})
export default class UpdateOrder extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;

    tabIndex = 0;

    order: OrderWithAddress = emptyOrderWithAddress();

    @Action invalidateOrder!: Function;

    get validOrderDetails(): boolean {
        return validateOrder(this.order);
    }

    get validDeliveryAddress(): boolean {
        return validateAddress(this.order.deliveryAddress);
    }

    done() {
        this.$emit("done");
    }

    submit() {
        if (this.validOrderDetails && this.validDeliveryAddress) {
            OrderApi.updateInformation(this.clientId, this.orderId, this.order.buyerName, this.order.buyerEmail,
                this.order.notes, this.order.iossNumber, this.order.deliveryAddress)
                .then(() => {
                    this.$parent.$bvToast.toast(this.$t("order-updated").toString(), SuccessToastSettings);
                    this.invalidateOrder();
                    this.$router.push({name: "orderOverview", params: {orderId: this.orderId.toString()}});
                })
                .catch(err => {
                    this.$parent.$bvToast.toast(this.$t("failed-update-order").toString(), ErrorToastSettings);

                    throw err;
                });
        }
    }

    created() {
        OrderApi.findWithAddressById(this.clientId, this.orderId)
            .then(data => {
                this.order = data;
            })
            .catch(err => {
                if (err.status === 404)
                    this.done();
                else {
                    this.$parent.$bvToast.toast(this.$t("failed-retrieve-order").toString(), ErrorToastSettings);

                    throw err;
                }
            });

    }
}
