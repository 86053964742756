










































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {Carrier, CarrierType} from "@/api/v1/CarrierApi";
import {ParcelShippingApi} from "@/api/v1/ParcelShippingApi";
import P2gShippingQuotesTable from "@/views/parcels/P2gShippingQuotesTable.vue";
import {FormatType, PackageFormat, RMCDParcelShippingApi} from "@/api/v1/RMCDParcelShippingApi";
import ClickAndDropShippingQuotesTable from "@/views/parcels/ClickAndDropShippingQuotesTable.vue";
import {ParcelApi} from "@/api/v1/ParcelApi";
import TransglobalExpressShippingQuotesTable from "@/views/parcels/TransglobalExpressShippingQuotesTable.vue";

@Component({components: {
    TransglobalExpressShippingQuotesTable,
    ClickAndDropShippingQuotesTable,
    P2gShippingQuotesTable,
}})
export default class ServiceSelection extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly parcelId!: Guid;
    @Prop({type: Object, required: true}) readonly carrier!: Carrier;
    @Prop({type: String, required: true}) readonly collectionAddressId!: Guid;

    packageFormats: PackageFormat[] = [];
    CarrierType: typeof CarrierType = CarrierType;
    selectedFormat: FormatType | null = null;
    serviceReference = "";

    async selectCustomService() {
        if (this.carrier.type == CarrierType.Custom) {
            await ParcelApi.useCollectionAddress(this.clientId, this.orderId, this.parcelId, this.collectionAddressId);

            ParcelShippingApi.buyPostage(this.clientId, this.parcelId, this.carrier.id,
                {carrierName: this.carrier.carrierName ?? "", serviceReference: this.serviceReference})
                .then(() => {
                    this.$router.push({
                        name: "parcelOverview",
                        params: {
                            clientId: this.clientId.toString(),
                            orderId: this.orderId.toString(),
                            parcelId: this.parcelId.toString()
                        }
                    });
                })
                .catch(err => {
                    //TODO add toast

                    throw err;
                });
        }
    }

    created() {
        if (this.carrier.type === CarrierType.ClickAndDrop) {
            RMCDParcelShippingApi.listFormats(this.clientId, this.parcelId, this.carrier.id)
                .then(data => {
                    this.packageFormats = data;

                    if (data.length === 1) {
                        this.selectedFormat = data[0].type;
                    } else {
                        this.selectedFormat = data.find(f => f.selected)?.type ?? null;
                    }
                });
        }
    }
}
