









































import {Component, Prop, Watch} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {Getter} from "vuex-class";
import {TimestampCache} from "@/store/cache";
import Helper from "@/components/helpers/Helper.vue";
import IconButton from "@/components/helpers/IconButton.vue";
import {User} from "@/api/v1/UserApi";
import Icon from "@/components/helpers/Icon.vue";
import {OrderUserAssignmentApi} from "@/api/v1/OrderUserAssignmentApi";
import Modal from "@/components/Modal.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import DataTable from "@/components/DataTable.vue";
import {emptyPaginatedList, ErrorToastSettings, IPaginatedList, IQueryRequest, QueryRequest} from "@/api/Utils";
import {ITableColumn} from "@/components/helpers/ITableColumn";

@Component({components: {IconButton, Icon, Modal, SelectionTable, DataTable}})
export default class OrderItemTable extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;

    dataProvider: Promise<IPaginatedList<User>> = Promise.resolve(emptyPaginatedList());
    query: IQueryRequest = new QueryRequest(1, 10, "id");
    selectedUser: User[] = [];
    showModal = false;

    @Getter tstpCache!: TimestampCache;

    get columns(): ITableColumn[] {
        return [
            {label: "Name", key: "name", sortable: false},
            {label: "Email", key: "email", sortable: false},
            {label: "", key: "rowActions", sortable: false}
        ];
    }

    queryChange(query: IQueryRequest) {
        this.query = query;
        this.refresh();
    }

    @Watch("tstpCache.orderUsers")
    refresh() {
        this.dataProvider = OrderUserAssignmentApi.findUsersAssignedToOrder(this.clientId, this.orderId, this.query);
    }

    closeModal() {
        this.showModal = false;
        this.selectedUser = [];
    }

    assignUser() {
        OrderUserAssignmentApi.assignUserToOrder(this.clientId, this.orderId, this.selectedUser[0]?.id)
            .then(() => {
                this.closeModal();
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast("Failed to assign user", ErrorToastSettings);

                throw err;
            });
    }

    unassignUser(userId: Guid) {
        OrderUserAssignmentApi.unassignUserFromOrder(this.clientId, this.orderId, userId)
            .then(() => {
                this.refresh();
            })
            .catch(err => {
                this.$bvToast.toast("Failed to unassign user", ErrorToastSettings);

                throw err;
            });
    }

    created() {
        this.refresh();
    }
}
