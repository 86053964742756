import {Guid} from "guid-typescript";
import {getRequest, postRequest} from "@/api/HttpMethods";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";

export const TGEParcelShippingApi = {
    listQuotes(clientId: Guid, parcelId: Guid, carrierId: Guid, queryRequest: IQueryRequest): Promise<IPaginatedList<ShippingQuote>> {
        return getRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/quotes`, queryRequest);
    },
    buyPostage(clientId: Guid, parcelId: Guid, carrierId: Guid, quote: ShippingQuote): Promise<void> {
        return postRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/purchase`, {quote});
    },
};

function getBaseUrl(clientId: Guid, parcelId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/parcels/${parcelId}/shipping/transglobal-express`;
}

export interface ShippingQuote {
    quoteReference: string;
    serviceReference: string;
    serviceName: string;
    isCollection: boolean;
    carrierName: string;
    transitTime: string;
    totalPrice: number;
}
