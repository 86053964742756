



































































import {Component, Prop, Vue} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {ActiveProduct, MetricsApi} from "@/api/v1/MetricsApi";
import {ClientShippingInfo, ClientShippingInfoApi, emptyClientShippingInfo} from "@/api/v1/ClientShippingInfoApi";
import {Getter} from "vuex-class";
import {UserIdentity} from "@/store/types";
import {Role} from "@/api/v1/UserApi";
import CountrySelect from "@/components/helpers/CountrySelect.vue";
import Toast from "@/components/Toast.vue";
import htmlEntitiesDecode from "html-entities-decoder";

@Component({components: {Toast, CountrySelect}})
export default class Overview extends Vue {
    @Prop({type: String, required: true}) readonly clientId!: Guid;

    @Getter getIdentity!: UserIdentity;

    outstandingOrders = 0;
    processingOrders = 0;
    dispatchedOrders = 0;
    ordersToDispatch = 0;

    productsToPrepare: ActiveProduct[] = [];

    shippingInfo: ClientShippingInfo | null = emptyClientShippingInfo();

    Role: typeof Role = Role;

    decode(str: string): string {
        return htmlEntitiesDecode(str);
    }

    async created() {
        if (this.getIdentity.role >= Role.Manager) {
            ClientShippingInfoApi.get(this.clientId)
                .then(data => {
                    this.shippingInfo = (data as any).status !== 204 ? data : null;
                });
        }

        if (this.getIdentity.role >= Role.Standard) {
            const orderCountByStatus = await MetricsApi.getNumberOfOrdersByStatus(this.clientId);
            this.outstandingOrders = orderCountByStatus.outstanding;
            this.processingOrders = orderCountByStatus.processing;
            this.dispatchedOrders = orderCountByStatus.dispatched;
            this.ordersToDispatch = await MetricsApi.getNumberOfOrdersToDispatch(this.clientId);

            this.productsToPrepare = await MetricsApi.getProductsToPrepare(this.clientId);
        }
    }
}
