import {Guid} from "guid-typescript";
import {getRequest, postRequest, putRequest} from "@/api/HttpMethods";
import {IPaginatedList, IQueryRequest} from "@/api/Utils";

export const RMCDParcelShippingApi = {
    listFormats(clientId: Guid, parcelId: Guid, carrierId: Guid): Promise<PackageFormat[]> {
        return getRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/formats`);
    },
    listQuotes(clientId: Guid, parcelId: Guid, carrierId: Guid, queryRequest: IQueryRequest): Promise<IPaginatedList<ShippingQuote>> {
        return getRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/quotes`, queryRequest);
    },
    buyPostage(clientId: Guid, parcelId: Guid, carrierId: Guid, quote: ShippingQuote, formatIdentifier: FormatType = FormatType.Undefined): Promise<void> {
        return postRequest(`${getBaseUrl(clientId, parcelId)}/${carrierId}/purchase`, {quote, formatIdentifier});
    },
    updateTrackingNumberFromSource(clientId: Guid, parcelId: Guid): Promise<void> {
        return putRequest(`${getBaseUrl(clientId, parcelId)}/tracking-number-from-source`);
    },
};

function getBaseUrl(clientId: Guid, parcelId: Guid): string {
    return `/internal/api/v1/clients/${clientId}/parcels/${parcelId}/shipping/click-and-drop`;
}

export interface ShippingQuote {
    quoteReference: string;
    serviceReference: string;
    serviceName: string;
    isCollection: boolean;
    carrierName: string;
    transitTime: string;
    totalPrice: number;
}

export interface PackageFormat {
    type: FormatType;
    name: string;
    selected: boolean;
}

export enum FormatType {
    Undefined,
    Letter,
    LargeLetter,
    SmallParcel,
    MediumParcel,
    Parcel,
    Documents
}
