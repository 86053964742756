




import {Vue, Component, Prop} from "vue-property-decorator";
import {OrderStatus} from "@/api/v1/OrderApi";

@Component
export default class Status extends Vue {
    @Prop({type: Number, required: true}) readonly value!: number;

    get status(): string {
        switch (this.value) {
            case OrderStatus.Outstanding:
                return "Outstanding";
            case OrderStatus.Processing:
                return "Processing";
            case OrderStatus.Dispatched:
                return "Dispatched";
            default:
                return `Unknown Status ${this.value}`;
        }
    }
}
