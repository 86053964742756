





































































































import {Component, Prop} from "vue-property-decorator";
import {Guid} from "guid-typescript";
import {CarrierApi, CarrierType} from "@/api/v1/CarrierApi";
import {FilteredQueryRequest, FilterOperand, IFilter} from "@/api/Utils";
import Modal from "@/components/Modal.vue";
import SelectionTable from "@/components/SelectionTable.vue";
import {TemplateType} from "@/api/v1/TemplateApi";
import {ParcelShippingApi} from "@/api/v1/ParcelShippingApi";
import {Address, AddressType, emptyAddress} from "@/api/v1/AddressApi";
import ServiceSelection from "@/views/parcels/ServiceSelection.vue";
import Helper from "@/components/helpers/Helper.vue";
import Tabs from "@/components/Tabs.vue";
import {ITab} from "@/api/IRouterTab";
import {emptyOrder, Order, OrderApi} from "@/api/v1/OrderApi";
import {ParcelApi} from "@/api/v1/ParcelApi";

@Component({components: {Tabs, ServiceSelection, SelectionTable, Modal}})
export default class ShipParcel extends Helper {
    @Prop({type: String, required: true}) readonly clientId!: Guid;
    @Prop({type: String, required: true}) readonly orderId!: Guid;
    @Prop({type: String, required: true}) readonly parcelId!: Guid;

    carriers: any[] = [];
    order: Order = emptyOrder();

    collectionAddress: Address = emptyAddress(AddressType.Collection);
    CarrierType: typeof CarrierType = CarrierType;

    carrierName = "Address Label Only";

    get breadcrumbs(): any[] {
        return [
            {text: this.$t("orders"), to: {name: "orders"}},
            {
                text: "Overview",
                to: {
                    name: "orderOverview",
                    params: {clientId: this.clientId.toString(), orderId: this.orderId.toString()}
                }
            },
            {text: this.$t("ship"), active: true}
        ];
    }

    get tabs(): ITab[] {
        return this.carriers.map((carrier, index) => {
            return {
                title: carrier.name,
                key: carrier.id
            };
        });
    }

    get labelTemplateSelectionFilters(): IFilter[] {
        return [
            {name: "type", op: FilterOperand.Equals, value: TemplateType.AddressLabel}
        ];
    }

    get collectionAddressFilter(): IFilter[] {
        return [
            {name: "type", op: FilterOperand.Equals, value: AddressType.Collection}
        ];
    }

    get extraData(): object | null {
        return (this.order.extraData ?? null) === null ? null : JSON.parse(this.order.extraData);
    }

    async useAddressLabel(carrierId: Guid, labelId: Guid) {
        await ParcelApi.useCollectionAddress(this.clientId, this.orderId, this.parcelId, this.collectionAddress.id);

        await ParcelShippingApi.buyPostage(this.clientId, this.parcelId, carrierId, {carrierName: this.carrierName, serviceReference: labelId.toString()});
        await this.$router.push({
            name: "parcelOverview",
            params: {
                clientId: this.clientId.toString(),
                orderId: this.orderId.toString(),
                parcelId: this.parcelId.toString()
            }
        });
    }

    clearAddress() {
        this.collectionAddress = emptyAddress(AddressType.Collection);
    }

    refresh() {
        CarrierApi.find(this.clientId, new FilteredQueryRequest(1, 100))
            .then(data => {
                this.carriers = data.list.sort((c, c2) => c.type.valueOf() - c2.type.valueOf());
            });

        OrderApi.findById(this.clientId, this.orderId)
            .then(data => {
                this.order = data;
            });
    }

    created() {
        this.refresh();
    }
}
